import { Node, Edge } from "reactflow"
import {
  UICell,
  UIColumnLabel,
  UIEdge,
  UILogic,
  UIRowLabel,
  UISheet,
} from "../types/UITypes"
import { getSheetDimensions } from "./2dsheet"

export type RemovesCellsOptions = {
  columnIndex?: number
  rowIndex?: number
  removeAll?: boolean
}

export const removeCells = (
  nodes: Node<UISheet | UILogic>[],
  edges: Edge<UIEdge>[],
  sheet: UISheet,
  removedCells: UICell[],
  options?: RemovesCellsOptions
) => {
  const removedCellIds = removedCells.map(({ id }) => id)

  let outgoingEdgesToBeDeleted: Edge<UIEdge>[] = []

  edges.forEach((edge) => {
    const edgeSourceSheetId = removedCells.find(
      (cell) => edge.data?.sourceSheetId === cell.sheetId
    )

    if (!edgeSourceSheetId) {
      return false
    }

    const edgeSourceRowCells = sheet.cells.filter(
      (cell) => cell.rowIndex === edge?.data?.sourceRowIndex
    )

    const edgeSourceRowCellIds = edgeSourceRowCells.map(({ id }) => id)

    const edgeSourceRowCellsAfterDeletion = edgeSourceRowCellIds.filter(
      (id) => !removedCellIds.includes(id)
    )

    // Add outgoing edge to be removed only if there is no cells left in that row.
    if (!edgeSourceRowCellsAfterDeletion.length) {
      outgoingEdgesToBeDeleted.push(edge)
    }
  })

  const edgesConnectedToLogics: Edge<UIEdge>[] = []
  const edgesConnectedToCells: Edge<UIEdge>[] = []

  outgoingEdgesToBeDeleted.forEach((edge) => {
    if (!!edge.data?.targetLogicId) {
      edgesConnectedToLogics.push(edge)
    }
    if (!!edge.data?.targetSheetId) {
      edgesConnectedToCells.push(edge)
    }
  })

  const connectedLogics = nodes.filter(
    (node) =>
      node.type === "logic" &&
      edgesConnectedToLogics.some(
        (edge) => edge.data?.targetLogicId === node.data.id
      )
  )

  let removedLogicsIds: string[] = []
  let removedEdgesIds: string[] = outgoingEdgesToBeDeleted.map(
    ({ data }) => data!.id
  )

  let filteredEdges = edges.filter(
    (edge) => !removedEdgesIds.includes(edge.data!.id)
  )
  const logicsToUpdate: Node<UILogic>[] = []
  const logicsToReplaceWithEdges: Node<UILogic>[] = []

  connectedLogics.forEach((logic) => {
    const logicData = logic.data as UILogic
    const updatedEdgeHandles = logicData.edgeHandles?.filter(
      (logicNode) =>
        !outgoingEdgesToBeDeleted.some(
          ({ targetHandle }) => targetHandle === `edge-${logicNode.id}`
        )
    )

    const incomingConnectionsLength =
      updatedEdgeHandles?.filter((node) => node.nodeType === "target")
        ?.length || 0

    if (incomingConnectionsLength === 0) {
      removedLogicsIds.push(logic.data.id)
      removedEdgesIds = removedEdgesIds.concat(
        filteredEdges
          .filter((edge) =>
            edge.sourceHandle?.startsWith(`edge-source-logic-${logic.id}`)
          )
          ?.map(({ data }) => data!.id)
      )
    } else {
      const updatedLogic: Node<UILogic> = {
        ...logic,
        data: {
          ...logicData,
          edgeHandles: updatedEdgeHandles,
        },
      }

      if (incomingConnectionsLength === 1) {
        logicsToReplaceWithEdges.push(updatedLogic)
      } else {
        logicsToUpdate.push(updatedLogic)
      }
    }
  })

  const edgesToReplaceLogics: Edge<UIEdge>[] = []

  if (logicsToReplaceWithEdges.length) {
    logicsToReplaceWithEdges.forEach((logic) => {
      const incomingEdge: Edge<UIEdge> | undefined = filteredEdges.find(
        (edge) => edge.data!.targetLogicId === logic.data.id
      )
      const outgoingEdge: Edge<UIEdge> | undefined = filteredEdges.find(
        (edge) => edge.data!.sourceLogicId === logic.data.id
      )

      if (incomingEdge && outgoingEdge) {
        incomingEdge.targetHandle = outgoingEdge.targetHandle
        incomingEdge.target = outgoingEdge.target

        if (incomingEdge.data) {
          incomingEdge.data.targetSheetId = outgoingEdge.data!.targetSheetId!
          incomingEdge.data.targetRowIndex = outgoingEdge.data!.targetRowIndex!
          incomingEdge.data.targetLogicId = null
        }

        if (
          options?.rowIndex &&
          incomingEdge.data?.sourceRowIndex &&
          options?.rowIndex < incomingEdge.data?.sourceRowIndex
        ) {
          incomingEdge.data!.sourceRowIndex =
            incomingEdge.data!.sourceRowIndex - 1
        }

        edgesToReplaceLogics.push(incomingEdge)
      }
    })

    removedEdgesIds = removedEdgesIds.concat(
      filteredEdges
        .filter((edge) =>
          logicsToReplaceWithEdges.some(
            (logic) => edge.data!.sourceLogicId === logic.data.id
          )
        )
        ?.map(({ data }) => data!.id)
    )
  }

  // delete incoming edges and logics
  let incomingEdgesToBeDeleted: Edge<UIEdge>[] = []
  edges.filter((edge) => {
    const edgeTargetSheetId = removedCells.find(
      (cell) => edge.data?.targetSheetId === cell.sheetId
    )

    if (!edgeTargetSheetId) {
      return false
    }

    const edgeTargetRowCells = sheet.cells.filter(
      (cell) => cell.rowIndex === edge?.data?.targetRowIndex
    )
    const edgeTargetRowCellsAfterDeletion = edgeTargetRowCells.filter(
      (cell) => !removedCellIds.includes(cell.id)
    )

    // Add incoming edge to be removed only if there is no cells left in that row.
    if (!edgeTargetRowCellsAfterDeletion.length) {
      incomingEdgesToBeDeleted.push(edge)
    }
  })

  removedEdgesIds = removedEdgesIds.concat(
    incomingEdgesToBeDeleted.map(({ data }) => data!.id)
  )

  incomingEdgesToBeDeleted.forEach((edge) => {
    if (edge.data!.sourceLogicId) {
      removedLogicsIds.push(edge.data!.sourceLogicId)
    }
  })

  removedEdgesIds = removedEdgesIds.concat(
    filteredEdges
      .filter(
        (edge) =>
          removedLogicsIds.includes(edge.data!.targetLogicId!) ||
          removedLogicsIds.includes(edge.data!.sourceLogicId!)
      )
      .map(({ data }) => data!.id)
  )

  // remove duplicates
  // TODO: refactor the code above so the duplicate edge IDs are not included.
  removedEdgesIds = Array.from(new Set(removedEdgesIds))

  filteredEdges = filteredEdges.filter(
    (edge) => !removedEdgesIds.includes(edge.data!.id)
  )

  let updatedNodes = [...nodes]
  removedLogicsIds = removedLogicsIds.concat(
    logicsToReplaceWithEdges.map(({ data }) => data.id)
  )

  if (removedLogicsIds.length) {
    updatedNodes = updatedNodes.filter(
      ({ data, type }) =>
        type !== "logic" || !removedLogicsIds.includes(data.id)
    )
  }

  const edgesById = edges.reduce(
    (acc, curr) => ({ ...acc, [curr.data!.id]: curr }),
    {} as Record<string, Edge<UIEdge>>
  )

  const updatedLogicsById = logicsToUpdate.reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr }),
    {} as Record<string, Node<UILogic>>
  )

  updatedNodes = updatedNodes.map((node) =>
    node.type !== "logic" || !updatedLogicsById[node.id]
      ? node
      : updatedLogicsById[node.id]
  )

  let removedColumnLabelIds: string[] = []
  let removedRowLabelIds: string[] = []

  if (options?.columnIndex) {
    const removedColumns =
      sheet.columnLabels?.filter(
        (columnLabel) => columnLabel.columnIndex === options.columnIndex
      ) || []

    removedColumnLabelIds = removedColumns.map(({ id }) => id)
  } else if (options?.removeAll && sheet.columnLabels) {
    removedColumnLabelIds = sheet.columnLabels.map(({ id }) => id)
  }

  if (options?.rowIndex) {
    const removedRows =
      sheet.rowLabels?.filter(
        (rowLabel) => rowLabel.rowIndex === options.rowIndex
      ) || []

    removedRowLabelIds = removedRows.map(({ id }) => id)
  } else if (options?.removeAll && sheet.rowLabels) {
    removedRowLabelIds = sheet.rowLabels.map(({ id }) => id)
  }

  const updatedColumnLabels: UIColumnLabel[] = []
  const updatedRowLabels: UIRowLabel[] = []

  removedEdgesIds.forEach((edgeId) => {
    const edge = edgesById[edgeId]
    const removedEdgeNodes = updatedNodes.filter(
      ({ id }) => id === edge.source || id === edge.target
    )

    removedEdgeNodes.forEach((node) => {
      if (node.type === "logic") {
        const logicData = node.data as UILogic

        logicData.edgeHandles = logicData.edgeHandles?.filter(
          (node) =>
            edge.sourceHandle !== `edge-${node.id}` &&
            edge.targetHandle !== `edge-${node.id}`
        )
      } else if (node.type === "sheet" || node.type === "matrix") {
        const sheetData = node.data as UISheet

        sheetData.cells = sheetData.cells?.map((cell) => ({
          ...cell,
          nodes: cell.nodes?.filter(
            (node) =>
              (edge.sourceHandle !== `edge-${node.id}` &&
                edge.targetHandle !== `edge-${node.id}`) ||
              edgesToReplaceLogics.some(
                (edge) => edge.targetHandle === `edge-${node.id}`
              )
          ),
        }))
      }
    })
  })

  let removedSheetId
  const updatedCells: UICell[] = []

  if (sheet.cells.length === removedCells.length) {
    removedSheetId = sheet.id
    updatedNodes = updatedNodes.filter(({ data }) => data.id !== sheet.id)
  }

  const removedEdges = edges.filter((edge) =>
    removedEdgesIds.includes(edge.data?.id!)
  )

  updatedNodes = updatedNodes.map((node) => {
    const outgoingRemovedEdges = removedEdges.filter(
      ({ data }) => data?.sourceSheetId === node.data.id
    )

    const incomingRemovedEdges = removedEdges.filter(
      ({ data }) => data?.targetSheetId === node.data.id
    )

    let updatedEdgeHandles = (node.data as UISheet).edgeHandles

    if (outgoingRemovedEdges.length) {
      updatedEdgeHandles = updatedEdgeHandles.filter(
        (edgeHandle) =>
          !(
            edgeHandle.nodeType === "source" &&
            outgoingRemovedEdges.some(
              (edge) => edge.data?.sourceRowIndex === edgeHandle.rowIndex
            )
          )
      )
    }

    if (incomingRemovedEdges.length) {
      updatedEdgeHandles = updatedEdgeHandles.filter(
        (edgeHandle) =>
          !(
            edgeHandle.nodeType === "target" &&
            outgoingRemovedEdges.some(
              (edge) => edge.data?.targetRowIndex === edgeHandle.rowIndex
            )
          )
      )
    }

    if (node.type === "logic") {
      return node
    }

    return {
      ...node,
      data: {
        ...node.data,
        cells: (node.data as UISheet).cells
          .filter((cell) => !removedCellIds.includes(cell.id))
          .map((cell) => {
            if (options?.columnIndex) {
              if (cell.columnIndex > options.columnIndex) {
                const updatedCell = {
                  ...cell,
                  columnIndex: cell.columnIndex - 1,
                }

                updatedCells.push(updatedCell)

                return updatedCell
              }

              return cell
            }

            if (options?.rowIndex) {
              if (cell.rowIndex < options.rowIndex) {
                return cell
              }

              const updatedCell = { ...cell, rowIndex: cell.rowIndex - 1 }

              updatedCells.push(updatedCell)

              return updatedCell
            }

            return cell
          }),

        columnLabels: (node.data as UISheet).columnLabels
          ?.filter(
            (columnLabel) => !removedColumnLabelIds.includes(columnLabel.id)
          )
          .map((columnLabel) => {
            if (options?.columnIndex) {
              if (columnLabel.columnIndex < options.columnIndex) {
                return columnLabel
              }

              const updatedColumnLabel = {
                ...columnLabel,
                columnIndex: columnLabel.columnIndex - 1,
              }

              updatedColumnLabels.push(updatedColumnLabel)

              return updatedColumnLabel
            }

            return columnLabel
          }),
        rowLabels: (node.data as UISheet).rowLabels
          ?.filter((rowLabel) => !removedRowLabelIds.includes(rowLabel.id))
          .map((rowLabel) => {
            if (options?.rowIndex) {
              if (rowLabel.rowIndex < options.rowIndex) {
                return rowLabel
              }

              const updatedRowLabel = {
                ...rowLabel,
                rowIndex: rowLabel.rowIndex - 1,
              }

              updatedRowLabels.push(updatedRowLabel)

              return updatedRowLabel
            }

            return rowLabel
          }),
        edgeHandles: updatedEdgeHandles,
      },
    }
  })

  const updatedEdges = [...edgesToReplaceLogics]

  edges.forEach((edge) => {
    if (
      edge.data?.sourceSheetId === sheet.id &&
      edge.data?.sourceRowIndex &&
      options?.rowIndex &&
      edge.data?.sourceRowIndex > options?.rowIndex &&
      updatedEdges.indexOf(edge) < 0
    ) {
      updatedEdges.push({
        ...edge,
        data: {
          ...edge.data,
          sourceRowIndex: edge.data.sourceRowIndex - 1,
        },
      })
    }
  })

  return {
    updatedEdges,
    updatedNodes,
    updatedCells,
    updatedColumnLabels,
    updatedRowLabels,
    removedEdgesIds,
    removedCellIds,
    removedColumnLabelIds,
    removedRowLabelIds,
    removedLogicsIds,
    removedSheetId,
  }
}
