import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { Session } from "@supabase/supabase-js"
import ReactModal from "react-modal"
import debounde from "lodash.debounce"

import { ReactComponent as AddSheetIcon } from "./assets/icons/add-sheet.svg"
import { ReactComponent as AddSheet2Icon } from "./assets/icons/add-sheet-2.0.svg"
import { ReactComponent as AutogenerateCanvasIcon } from "./assets/icons/autogenerate-canvas.svg"
import { ReactComponent as MinimapIcon } from "./assets/icons/minimap.svg"
import ReactFlow, {
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  Node,
  Connection,
  ReactFlowInstance,
  EdgeProps,
  Edge,
  Viewport,
  NodePositionChange,
  NodeChange,
  MiniMap,
} from "reactflow"
import { PublishedApi, supabase } from "./database/SupabaseConnector"

import "./App.css"
import "reactflow/dist/style.css"
import { AppContext, CellWithContextMenuInfoType } from "./AppContext"
import {
  SheetField,
  UICell,
  UIEdge,
  UILogic,
  UILogicType,
  UISheet,
} from "./types/UITypes"
import { EdgeRenderer } from "./components/EdgeRenderer"
import { EdgePreview } from "./components/EdgePreview"
import { RemovesCellsOptions, removeCells } from "./utils/removeCells"
import { addCellToCellConnection } from "./utils/addCellToCellConnection"
import { addUIEdgeToLogic } from "./utils/addUIEdgeToLogic"
import { addUILogicConnection } from "./utils/addUILogicConnection"
import {
  CustomerSettings,
  DatabaseCanvas,
  DatabaseLogic,
} from "./types/SupabaseTypesHelper"
import { getApiParams } from "./utils/apiUtils"
import { useSupabaseConnector } from "./hooks/useSupabaseConnector"
import "react-json-view-lite/dist/index.css"
import clsx from "clsx"
import { areCellsConnectedThroughLogic } from "./utils/connectionValidation"
import { useNavigate } from "react-router-dom"
import {
  CanvasPermission,
  edgeSourceSheetHandleRegExp,
  edgeTargetSheetHandleRegExp,
} from "./utils/constants"
import LogicRenderer from "./components/logic-renderer/LogicRenderer"
import SheetRenderer from "./components/sheet-renderer/SheetRenderer"
import MatrixRenderer from "./components/sheet-renderer/MatrixRenderer"
import { insertColumn, insertRow } from "./utils/2dsheet"
import SheetPreviewRenderer from "./components/sheet-renderer/SheetPreviewRenderer"
import { buildCellsDependencyTree } from "./utils/buildCellDependencyTree"
import { getFirebaseTemplate } from "./utils/getFirebaseApiFunction"
import {
  removeCellToCellEdge,
  removeEdgeWithRemainingIncomingConnections,
  removeEdgeWithoutRemainingIncomingConnections,
  removeLogicToCellEdge,
} from "./utils/removeCellToLogicEdge"
import { SheetValidationType } from "./utils/validation"
import { uuidv7 } from "uuidv7"
import PdfToImg from "pdftoimg-js/browser"
import { fileToBase64, getFullURL } from "./utils/common"
import MatrixPreviewRenderer from "./components/sheet-renderer/MatrixPreviewRenderer"
import { parseAutogenSheets } from "./utils/autogenSheets"
import { parseAutogenMatrices } from "./utils/autogenMatrices"
import { AutogenModal } from "./components/AutogenModal"
import { UpgradeToProModal } from "./components/UpgradeToProModal"
import { PublishApiModal } from "./components/PublishApiModal"

const BACKGROUND_DOTS_GAP = 16

const nodeTypes = {
  sheet: SheetRenderer,
  matrix: MatrixRenderer,
  logic: LogicRenderer,
}

const edgeTypes = {
  custom: EdgeRenderer,
}

const App = () => {
  const [session, setSession] = useState<Session | null | undefined>()
  const [nodes, setNodes] = useNodesState<UISheet | UILogic>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [selectedNodes, setSelectedNodes] = useState<string[]>([])
  const [selectedEdges, setSelectedEdges] = useState<string[]>([])
  const [isInitialized, setInitialized] = useState(false)
  const [sheetFields, setSheetFields] = useState<SheetField[]>([])
  const [api, setApi] = useState<PublishedApi[]>()
  const [cellsDependencyTree, setCellsDependencyTree] = useState<
    Record<string, { logics: string[]; cells: string[] }>
  >({})
  const [isApiPreviewVisible, setIsApiPreviewVisible] = useState(false)
  const [isApiDialogOpen, setApiDialogOpen] = useState(false)
  const [connectionStart, setConnectionStart] =
    useState<Pick<Connection, "source" | "sourceHandle">>()
  const [canvas, setCanvas] = useState<DatabaseCanvas>()
  const [isUserModalOpen, setUserModalOpen] = useState(false)
  const [isMatrixLabelEditorOpen, setMatrixLabelEditorOpen] = useState(false)
  const [isLoadingAPI, setIsLoadingAPI] = useState(false)
  const [userRole, setUserRole] = useState("dev")
  const [isEditingLabel, setIsEditingLabel] = useState(false)
  const [isPendingApiResponse, setPendingApiResponse] = useState(false)
  const [isPendingNodesUpdate, setPendingNodesUpdate] = useState(false)
  const [isSheetsUIVisible, setIsSheetsUIVisible] = useState(false)
  const reactFlowRef = useRef<ReactFlowInstance | null>(null)
  const matrixLabelInputRef = useRef<HTMLInputElement | null>(null)
  const isDraggingNode = useRef<boolean>(false)
  const refreshApiPreviewTimeout = useRef<NodeJS.Timeout>()
  const supabaseConnector = useSupabaseConnector(canvas?.id)

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    const pendingChanges: Record<string, NodeChange> = {}
    let hasPendingChanges = false

    changes.forEach((change) => {
      if (change.type === "position" && change.position) {
        pendingChanges[change.id] = change
        hasPendingChanges = true
      }
    })

    if (hasPendingChanges) {
      setNodes((nodes) =>
        nodes.map((node) => {
          const change: NodeChange = pendingChanges[node.id]

          if (change) {
            if (
              change.type === "position" &&
              (change as NodePositionChange).position
            ) {
              return {
                ...node,
                position: change.position!,
              }
            }

            return node
          }

          return node
        })
      )
    }
  }, [])

  const handleInit = (reactFlowInstance: ReactFlowInstance) => {
    reactFlowRef.current = reactFlowInstance
  }

  const handleConnect = useCallback(
    async (connection: Connection) => {
      const { source, target, sourceHandle, targetHandle } = connection

      if (!source || !target || source === target) {
        return
      }

      const sourceSheetHandleMatch = sourceHandle?.match(
        edgeSourceSheetHandleRegExp
      )
      const targetSheetHandleMatch = targetHandle?.match(
        edgeTargetSheetHandleRegExp
      )

      const sourceSheetId = sourceSheetHandleMatch && sourceSheetHandleMatch[1]
      const sourceRowIndex = sourceSheetHandleMatch && sourceSheetHandleMatch[2]

      const targetSheetId = targetSheetHandleMatch && targetSheetHandleMatch[1]
      const targetRowIndex = targetSheetHandleMatch && targetSheetHandleMatch[2]

      if (
        !sourceSheetId ||
        !targetSheetId ||
        !sourceRowIndex ||
        !targetRowIndex ||
        !source ||
        !target ||
        edges.some(
          (edge) =>
            edge.sourceHandle?.replace(/edge-/, "").replace(/-node-.*/, "") ===
              sourceHandle &&
            edge.targetHandle?.replace(/edge-/, "").replace(/-node-.*/, "") ===
              targetHandle
        ) ||
        areCellsConnectedThroughLogic(
          nodes,
          edges,
          connection,
          sourceSheetId,
          sourceRowIndex,
          targetSheetId,
          targetRowIndex
        )
      ) {
        return
      }

      // hande ReactFlow edge case
      const edgeTargethandlePreffix = targetHandle?.startsWith("edge")
        ? targetHandle
        : `edge-${targetHandle}`
      const destCellIncomingEdge = edges.find((edge) =>
        edge.targetHandle?.startsWith(edgeTargethandlePreffix)
      )

      // Add new logic connection
      if (destCellIncomingEdge?.data.sourceSheetId) {
        const { updatedNodes, updatedEdges, newLogic, newEdges } =
          addUILogicConnection(
            nodes,
            edges,
            connection,
            sourceSheetId,
            targetSheetId,
            sourceRowIndex,
            targetRowIndex,
            destCellIncomingEdge,
            reactFlowRef.current!,
            UILogicType.AND
          )

        setEdges(updatedEdges)
        setNodes(updatedNodes)

        await supabaseConnector.addLogicConnection(
          newLogic,
          destCellIncomingEdge,
          newEdges
        )
      } else if (destCellIncomingEdge?.data.sourceLogicId) {
        // Add connection to existing logic
        const { updatedNodes, updatedEdges, newEdge } = addUIEdgeToLogic(
          nodes,
          edges,
          connection,
          sourceSheetId,
          sourceRowIndex,
          destCellIncomingEdge
        )

        setNodes(updatedNodes)
        setEdges(updatedEdges)

        await supabaseConnector.addEdge(newEdge)
      } else {
        // Connect cell to cell
        const { updatedNodes, updatedEdges, newEdge } = addCellToCellConnection(
          nodes,
          edges,
          connection,
          sourceSheetId,
          targetSheetId,
          sourceRowIndex,
          targetRowIndex
        )

        setNodes(updatedNodes)
        setEdges(updatedEdges)

        await supabaseConnector.addEdge(newEdge)
      }
    },
    [nodes, edges]
  )

  useEffect(() => {
    supabase.auth.getSession().then((response) => {
      const {
        data: { session },
      } = response
      // session?.access_token << JWT
      setSession(session)
    })

    const authChangeEvent = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session)

        if (!session) {
          setInitialized(false)
          setUserModalOpen(false)
          setNodes([])
          setEdges([])
          setApi([])
        }
      }
    )

    return () => authChangeEvent.data.subscription.unsubscribe()
  }, [])

  const canvasPermissions = useMemo<Record<string, boolean>>(() => {
    return (
      session?.user?.app_metadata?.canvas_permission?.reduce(
        (acc: Record<string, boolean>, curr: string) => ({
          ...acc,
          [curr]: true,
        }),
        {}
      ) || {}
    )
  }, [session])

  useEffect(() => {
    if (session?.user && !isInitialized) {
      fetchData()
    }
  }, [session, isInitialized])

  useEffect(() => {
    if (!canvasPermissions) {
      return
    }

    if (
      canvasPermissions[CanvasPermission.Dev] &&
      !canvasPermissions[CanvasPermission.PMBiz]
    ) {
      setUserRole("dev")
    } else if (
      !canvasPermissions[CanvasPermission.Dev] &&
      canvasPermissions[CanvasPermission.PMBiz]
    ) {
      setUserRole("pmBiz")
    } else if (
      canvasPermissions[CanvasPermission.Dev] &&
      canvasPermissions[CanvasPermission.PMBiz]
    ) {
      setUserRole("superuser")
    }
  }, [canvasPermissions])

  const [defaultViewport, setDefaultViewport] = useState<Viewport>({
    x: 0,
    y: 0,
    zoom: 1,
  })

  const [customerSettings, setCustomerSettings] = useState<CustomerSettings>()

  async function fetchData() {
    const canvases = await supabaseConnector.getCanvases()
    let canvas: DatabaseCanvas | undefined

    if (!canvases?.length) {
      canvas = await supabaseConnector.createCanvas()
    } else {
      canvas = canvases[0]
    }

    setCanvas(canvas)

    if (!canvas) {
      return
    }

    setCanvasName(canvas?.name || `Canvas-${canvas?.id}`)

    supabaseConnector.setCanvasId(canvas?.id!)

    const { sheets, logics, edges, dataSheet } =
      await supabaseConnector.fetchData()

    // const api = await supabaseConnector.getAPI()

    if (!sheets) {
      return
    }

    setNodes([...sheets, ...logics])
    setEdges(edges)
    // setApi(api)

    if (dataSheet?.data?.cells?.length) {
      const sheetFields = dataSheet?.data?.cells?.map((cell) => {
        const [name, alias] = cell.name?.split(":") || []

        return {
          id: cell.id,
          name,
          alias,
          type: cell.valueType,
          rowIndex: cell.rowIndex,
        }
      })

      sheetFields.sort((a, b) => a.rowIndex - b.rowIndex)

      setSheetFields(sheetFields)
    }

    const { data: canvasSettings } = await supabase
      .from("user_settings")
      .select()
      .eq("canvas_id", canvas?.id)

    const { data: customerSettings } = await supabase
      .from("customer_settings")
      .select()
      .eq("customer_team", session?.user.app_metadata.customer_team)

    customerSettings?.length && setCustomerSettings(customerSettings[0])

    if (canvasSettings?.length) {
      const { canvas_x, canvas_y, canvas_zoom } = canvasSettings[0]
      setDefaultViewport({
        x: canvas_x || 0,
        y: canvas_y || 0,
        zoom: canvas_zoom || 1,
      })
    }

    setInitialized(true)
  }

  const [sheetValidationErrors, setSheetValidationErrors] =
    useState<SheetValidationType>({})

  // useEffect(() => {
  //   const updatedValidationErrors = getValidationErrors(nodes)

  //   setSheetValidationErrors(updatedValidationErrors)
  // }, [nodes, selectedNodes])

  const handleRemoveEdge = useCallback(
    async (edgeProps: EdgeProps<UIEdge>) => {
      const { source, target, sourceHandleId, targetHandleId } = edgeProps

      const {
        id,
        sourceLogicId,
        targetLogicId,
        sourceRowIndex,
        targetRowIndex,
      } = edgeProps?.data!

      const edgeSourceNodeId = sourceHandleId?.replace("edge-", "")
      const edgeTargetNodeId = targetHandleId?.replace("edge-", "")

      if (!edgeSourceNodeId || !edgeTargetNodeId) {
        return
      }

      const sourceSheet = nodes.find(
        ({ id, type }) => id === source
      ) as Node<UISheet>
      const targetSheet = nodes.find(
        ({ id, type }) => id === target
      ) as Node<UISheet>
      const sourceLogic = nodes.find(
        ({ id, type }) => id === source
      ) as Node<UILogic>
      const targetLogic = nodes.find(
        ({ id, type }) => id === target
      ) as Node<UILogic>

      if (sourceSheet && targetSheet) {
        const updatedNodes = removeCellToCellEdge(
          nodes,
          sourceSheet,
          targetSheet,
          edgeSourceNodeId,
          edgeTargetNodeId
        )

        setNodes(updatedNodes)
        setEdges((edges) => edges.filter((edge) => edge.data?.id !== id))

        supabaseConnector.removeEdge(id)
      }

      if (sourceRowIndex && targetLogicId) {
        const remainingIncomingConnections =
          targetLogic.data?.edgeHandles?.filter(
            (edgeHandle) =>
              edgeHandle.nodeType === "target" &&
              edgeHandle.id !== edgeTargetNodeId
          )

        if (!remainingIncomingConnections?.length) {
          return
        }

        if (remainingIncomingConnections.length > 1) {
          const updatedNodes = removeEdgeWithRemainingIncomingConnections(
            sourceSheet,
            targetLogic,
            edgeSourceNodeId,
            edgeTargetNodeId,
            nodes
          )

          setNodes(updatedNodes)
          setEdges((edges) => edges.filter((edge) => edge.data?.id !== id))

          supabaseConnector.removeEdge(id)
        } else {
          const updatedEntitiesAfterEdgeRemoval =
            removeEdgeWithoutRemainingIncomingConnections(
              edges,
              nodes,
              remainingIncomingConnections,
              targetLogic,
              targetLogicId,
              source,
              edgeSourceNodeId,
              id
            )

          if (!updatedEntitiesAfterEdgeRemoval) {
            return
          }

          const {
            updatedNodes,
            updatedEdges,
            removedLogicOutgoingEdge,
            edgeToUpdate,
          } = updatedEntitiesAfterEdgeRemoval

          setNodes(updatedNodes)
          setEdges(updatedEdges)

          await supabaseConnector.replaceLogicWithEdge(
            targetLogicId,
            edgeToUpdate,
            [id, removedLogicOutgoingEdge.data.id]
          )
        }
      }

      if (sourceLogicId && targetRowIndex) {
        const { updatedNodes, removedEdges } = removeLogicToCellEdge(
          nodes,
          edges,
          sourceLogic,
          targetSheet,
          edgeTargetNodeId,
          sourceLogicId,
          target
        )

        setNodes(updatedNodes)

        setEdges((edges) =>
          edges.filter((edge) => !removedEdges.includes(edge))
        )

        await supabaseConnector.handleRemoveLogic(
          sourceLogicId,
          removedEdges.map(({ data }) => data.id) as string[]
        )
      }
    },
    [nodes, edges, supabaseConnector]
  )

  const handleRemoveCells = useCallback(
    async (
      sheet: UISheet,
      removedCells: UICell[],
      options?: RemovesCellsOptions
    ) => {
      const {
        updatedEdges,
        updatedNodes,
        updatedCells,
        updatedColumnLabels,
        updatedRowLabels,
        removedEdgesIds,
        removedCellIds,
        removedColumnLabelIds,
        removedRowLabelIds,
        removedLogicsIds,
        removedSheetId,
      } = removeCells(nodes, edges, sheet, removedCells, options)

      setEdges((edges) =>
        edges
          .filter(({ data }) => !removedEdgesIds?.includes(data?.id))
          .map(
            (edge) =>
              updatedEdges.find((updatedEdge) => updatedEdge.id === edge.id) ||
              edge
          )
      )

      setNodes(updatedNodes)

      setPendingApiResponse(true)

      await supabaseConnector.handleRemoveCells(
        removedEdgesIds,
        removedCellIds,
        removedLogicsIds,
        removedColumnLabelIds,
        removedRowLabelIds,
        updatedEdges,
        updatedCells,
        updatedColumnLabels,
        updatedRowLabels,
        removedSheetId
      )

      setPendingApiResponse(false)
    },
    [nodes, edges]
  )

  const handleNodeDrag = useCallback(() => {
    if (!isDraggingNode.current) {
      isDraggingNode.current = true
    }
  }, [])

  const handleNodeDragStop = useCallback(
    async (event: MouseEvent, node: Node) => {
      if (!isDraggingNode.current || !node) {
        return
      }

      isDraggingNode.current = false

      // Looks like there is some kind of internal logic of the ReactFlow that uses those offsets.
      // TODO: find out if they're exposed in the ReactFlow API.
      const GRID_X_OFFSET = 4
      const GRID_Y_OFFSET = -2
      const nodeGridStepsX = Math.round(node.position.x / BACKGROUND_DOTS_GAP)
      const nodeX = nodeGridStepsX * BACKGROUND_DOTS_GAP + GRID_X_OFFSET
      const nodeGridStepsY = Math.round(node.position.y / BACKGROUND_DOTS_GAP)
      const nodeY = nodeGridStepsY * BACKGROUND_DOTS_GAP - GRID_Y_OFFSET
      const updatedNode = {
        ...node,
        position: { x: nodeX, y: nodeY },
      }

      setNodes(
        nodes.map((prevNode) =>
          prevNode.id !== node.id ? prevNode : updatedNode
        )
      )

      await supabaseConnector.updateNode(updatedNode)
    },
    [nodes]
  )

  const handleUpdateLogic = useCallback(
    (logicData: DatabaseLogic) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.type !== "logic" || node.data.id !== logicData.id) {
            return node
          }

          return {
            ...node,
            data: {
              ...node.data,
              logicType: logicData.logic_type as string,
              name: logicData.logic_type === UILogicType.AND ? "AND" : "OR",
            },
          }
        })
      )
    },
    [setNodes]
  )

  const [pendingSheetSelectionId, setPendingSheetSelectionId] = useState<
    string | null
  >(null)

  const handleHighlightedPathDestCellIdChange = (cellId?: string) => {
    if (cellId) {
      setHighlightedPathCellIds(cellsDependencyTree[cellId]?.cells || [])
      setHighlightedPathLogicIds(cellsDependencyTree[cellId]?.logics || [])
    } else {
      setHighlightedPathCellIds([])
      setHighlightedPathLogicIds([])
    }
  }

  const [highlightedPathCellIds, setHighlightedPathCellIds] = useState<
    string[]
  >([])

  const [highlightedPathLogicIds, setHighlightedPathLogicIds] = useState<
    string[]
  >([])

  const handleAddSheet = async (isMatrix?: boolean) => {
    const sheetWidth = 250
    const sheetHeight = 115
    const centerCoords = reactFlowRef.current?.project({
      x: (window.innerWidth - sheetWidth) / 2,
      y: (window.innerHeight - sheetHeight) / 2,
    })

    const sheetId = uuidv7()
    const cells = [
      {
        id: uuidv7(),
        rowIndex: 1,
        columnIndex: 1,
        sheetId: sheetId,
      },
    ]
    const updatedNodes = [
      ...nodes,
      {
        id: `sheet-${sheetId}`,
        position: {
          x: centerCoords?.x || 0,
          y: centerCoords?.y || 0,
        },
        data: {
          id: sheetId,
          x: centerCoords?.x || 0,
          y: centerCoords?.y || 0,
          cells,
          edgeHandles: [],
        },
        width: sheetWidth,
        height: sheetHeight,
        type: isMatrix ? "matrix" : "sheet",
      },
    ]

    setNodes(updatedNodes)
    await supabaseConnector.addSheet(centerCoords!, sheetId, cells, isMatrix)

    if (isMatrix) {
      await supabase
        .from("column_label_label")
        .insert({ canvas_id: canvas?.id!, sheet_id: sheetId, text: "" })
      await supabase
        .from("row_label_label")
        .insert({ canvas_id: canvas?.id!, sheet_id: sheetId, text: "" })
    }

    setPendingSheetSelectionId(`sheet-${sheetId}`)
  }

  const handlePublishToFirebase = async () => {
    const api = supabaseConnector.getAPI(nodes, edges, sheetFields)

    setApi(api)

    const apiFuntion = getFirebaseTemplate(api)

    navigator.clipboard.writeText(apiFuntion)

    alert("Firebase function code is copied to the clipboard.")
  }

  const handlePublish = async (deploymentEnv: string) => {
    const api = supabaseConnector.getAPI(nodes, edges, sheetFields)
    setApi(api)

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        api,
        canvasId: canvas?.id,
        deploymentEnv,
        tenantId: session?.user.app_metadata.customer_team,
      }),
    }

    await fetch(
      "https://deploy-canvas-logic-function.fyngo.workers.dev/",
      options
    )
  }

  const apiPreview = useMemo(() => {
    return api?.map((apiItem) => ({
      sheetId: apiItem.sheetId,
      name: apiItem.sheetName,
      sheetData: nodes.find((node) => node.data.id === apiItem.sheetId),
      requestPayload: {
        inputData: getApiParams(apiItem, sheetFields),
        apiSheetId: apiItem.sheetId,
      },
      responseKeys: apiItem.cells
        .filter((cell) => cell.prev)
        .map((cell) => cell.name),
    }))
  }, [api, nodes])

  const handleViewAndDeployAPI = async () => {
    if (!isApiPreviewVisible) {
      const api = supabaseConnector.getAPI(nodes, edges, sheetFields)
      setApi(api)
    }
    setIsApiPreviewVisible(!isApiPreviewVisible)
  }

  const [pendingExtendSheetParams, setPendingExtendSheetParams] = useState<{
    sheetId: string
    rowIndex?: number
    columnIndex?: number
  }>()

  const [canvasName, setCanvasName] = useState("")

  useEffect(() => {
    if (!isPendingNodesUpdate && pendingExtendSheetParams) {
      const { sheetId, rowIndex, columnIndex } = pendingExtendSheetParams

      setPendingExtendSheetParams(undefined)

      if (rowIndex) {
        handleAddRow(sheetId, rowIndex)
      } else if (columnIndex) {
        handleAddColumn(sheetId, columnIndex)
      }
    }
  }, [isPendingNodesUpdate, pendingExtendSheetParams])

  const handleAddRow = async (sheetId: string, rowIndex: number) => {
    const sheet: UISheet = nodes.find((node) => node.data.id === sheetId)
      ?.data as UISheet

    if (!sheet) {
      return Promise.resolve()
    }

    setPendingApiResponse(true)

    if (isPendingNodesUpdate) {
      setPendingExtendSheetParams({ sheetId, rowIndex })
      return
    }

    const [updatedCells, newCells] = insertRow(
      sheet.cells,
      rowIndex + 1,
      sheetId
    )

    const updatedNodes = nodes.map((node) =>
      node.data.id !== sheetId || node.id.startsWith("logic")
        ? node
        : {
            ...node,
            data: {
              ...node.data,
              cells: updatedCells,
              edgeHandles: node.data.edgeHandles.map((edgeHandle) =>
                edgeHandle.rowIndex! < rowIndex
                  ? edgeHandle
                  : { ...edgeHandle, rowIndex: edgeHandle.rowIndex! + 1 }
              ),
            },
          }
    )

    const updatedEdges: Edge<UIEdge>[] = []

    setNodes(updatedNodes)

    setEdges(
      edges.map((edge) => {
        if (
          edge.data.sourceSheetId !== sheetId ||
          edge.data.sourceRowIndex < rowIndex
        ) {
          return edge
        }

        const updatedEdge = {
          ...edge,
          data: {
            ...edge.data,
            sourceRowIndex: edge.data.sourceRowIndex + 1,
          },
        }

        updatedEdges.push(updatedEdge)

        return updatedEdge
      })
    )

    await supabaseConnector.createCells(newCells)

    // Update rowIndex of the cells below the inserted row
    await supabaseConnector.updateCells(
      updatedCells.filter((cell) => cell.rowIndex > rowIndex)
    )

    // Update rowIndex of the edges below the inserted row
    await supabaseConnector.updateEdges(updatedEdges)

    setPendingApiResponse(false)
  }

  const refreshApiPreview = () => {
    refreshApiPreviewTimeout.current = setTimeout(() => {
      if (!reactFlowRef.current) {
        return
      }

      try {
        const api = supabaseConnector.getAPI(
          reactFlowRef.current.getNodes(),
          reactFlowRef.current.getEdges(),
          sheetFields
        )
        const cellsDependencyTree = buildCellsDependencyTree(api)

        setCellsDependencyTree(cellsDependencyTree)
        setApi(api)
      } catch (e) {
        refreshApiPreview()
        console.log(e, "Warning: API live preview error.")
      }
    }, 1000)
  }

  useEffect(() => {
    if (isPendingApiResponse) {
      return
    }

    refreshApiPreview()

    return () => {
      clearTimeout(refreshApiPreviewTimeout.current)
    }
  }, [nodes, edges, sheetFields, isPendingApiResponse])

  const handleShrinkSheet = async (
    sheetId: string,
    maxColumns: number | null,
    maxRows: number | null
  ) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.data.id === sheetId
          ? {
              ...node,
              data: {
                ...node.data,
                cells: (node.data as UISheet).cells.filter(
                  (cell) =>
                    (!maxColumns || cell.columnIndex <= maxColumns) &&
                    (!maxRows || cell.rowIndex <= maxRows)
                ),
              },
            }
          : node
      )
    )
    supabaseConnector.shrinkSheet(sheetId, maxColumns, maxRows)
  }

  const handleAddCells = async (
    sheetId: string,
    cells: UICell[],
    nodes: Node[]
  ) => {
    const updatedNodes = nodes.map((node) =>
      node.data.id !== sheetId
        ? node
        : {
            ...node,
            data: {
              ...node.data,
              cells: [
                ...(node.data as UISheet).cells,
                ...cells.map((cell) => ({ ...cell, isNew: false })),
              ],
            },
          }
    )

    setNodes(updatedNodes)
    await supabaseConnector.createCells(cells)
  }

  const handleAddColumn = async (sheetId: string, columnIndex: number) => {
    const sheet: UISheet = nodes.find((node) => node.data.id === sheetId)
      ?.data as UISheet

    if (!sheet) {
      return Promise.resolve()
    }

    setPendingApiResponse(true)

    if (isPendingNodesUpdate) {
      setPendingExtendSheetParams({ sheetId, columnIndex })
      return
    }

    const { updatedCells, newCells, updatedColumnLabels } = insertColumn(
      sheet,
      sheet.complex ? columnIndex : columnIndex + 1,
      sheetId
    )

    const updatedNodes = nodes.map((node) =>
      node.data.id !== sheetId
        ? node
        : {
            ...node,
            data: {
              ...node.data,
              cells: updatedCells,
              columnLabels: updatedColumnLabels,
            },
          }
    )

    setNodes(updatedNodes)

    await supabaseConnector.createCells(newCells)

    // Update columnIndex of the cells right to the inserted column
    // TODO: use rpc
    await supabaseConnector.updateCells(
      updatedCells.filter((cell) => cell.columnIndex > columnIndex)
    )

    // TODO: use rpc
    const updatedColumnLabelsPromises: Promise<any>[] = updatedColumnLabels
      .filter((columnLabel) => columnLabel.columnIndex > columnIndex)
      .map((columnLabel) => supabaseConnector.updateColumnLabel(columnLabel))

    await Promise.all(updatedColumnLabelsPromises)

    setPendingApiResponse(false)

    return Promise.resolve()
  }

  const handleRemoveRow = (sheetId: string, rowIndex: number) => {
    const sheet: UISheet = nodes.find((node) => node.data.id === sheetId)
      ?.data as UISheet

    if (!sheet) {
      return Promise.resolve()
    }

    const removedCells = sheet.cells.filter(
      (cell) => cell.rowIndex === rowIndex
    )

    handleRemoveCells(sheet, removedCells, { rowIndex })

    return Promise.resolve()
  }

  const handleRemoveColumn = async (sheetId: string, columnIndex: number) => {
    const sheet: UISheet = nodes.find((node) => node.data.id === sheetId)
      ?.data as UISheet

    if (!sheet) {
      return Promise.resolve()
    }

    const removedCells = sheet.cells.filter(
      (cell) => cell.columnIndex === columnIndex
    )

    await handleRemoveCells(sheet, removedCells, { columnIndex })

    return Promise.resolve()
  }

  const handleUpdateCell = async (
    updatedCellValue: string,
    cellId: string,
    sheetId: string
  ) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        return node.id === sheetId
          ? {
              ...node,
              data: {
                ...node.data,
                cells: (node.data as UISheet).cells.map((cell) =>
                  cell.id === cellId
                    ? {
                        ...cell,
                        name: updatedCellValue,
                      }
                    : cell
                ),
              },
            }
          : node
      })
    )

    return await supabaseConnector.updateCellValue(updatedCellValue, cellId)
  }

  const handleUpdateSheet = async (sheet: UISheet) => {
    return await supabaseConnector.updateSheetName(sheet)
  }

  const handleUpdateColumnLabel = async (
    sheetId: string,
    columnIndex: number,
    text: string
  ) => {
    if (!canvas) {
      return
    }

    setPendingNodesUpdate(true)

    const response = await supabase
      .from("column_label")
      .select()
      .eq("sheet_id", sheetId)
      .eq("column_index", columnIndex)

    if (!response.data?.length) {
      const { data } = await supabase
        .from("column_label")
        .insert({
          canvas_id: canvas.id,
          sheet_id: sheetId,
          column_index: columnIndex,
          text,
        })
        .select()

      if (data?.length) {
        const updatedNodes = nodes.map((node) =>
          node.data.id !== sheetId
            ? node
            : {
                ...node,
                data: {
                  ...node.data,
                  columnLabels: [
                    ...((node.data as UISheet).columnLabels || []),
                    { id: data[0].id, columnIndex, text },
                  ],
                },
              }
        )
        setNodes(updatedNodes)
      }
    } else {
      await supabase
        .from("column_label")
        .update({
          text,
        })
        .eq("sheet_id", sheetId)
        .eq("column_index", columnIndex)

      const updatedNodes = nodes.map((node) =>
        node.data.id !== sheetId
          ? node
          : {
              ...node,
              data: {
                ...node.data,
                columnLabels: [
                  ...((node.data as UISheet).columnLabels || []).map(
                    (columnLabel) =>
                      columnLabel.columnIndex === columnIndex
                        ? { ...columnLabel, text }
                        : columnLabel
                  ),
                ],
              },
            }
      )
      setNodes(updatedNodes)
    }

    setPendingNodesUpdate(false)
  }

  const handleUpdateRowLabel = async (
    sheetId: string,
    rowIndex: number,
    text: string
  ) => {
    if (!canvas) {
      return
    }

    setPendingNodesUpdate(true)

    const response = await supabase
      .from("row_label")
      .select()
      .eq("sheet_id", sheetId)
      .eq("row_index", rowIndex)

    if (!response.data?.length) {
      const { data } = await supabase
        .from("row_label")
        .insert({
          canvas_id: canvas.id,
          sheet_id: sheetId,
          row_index: rowIndex,
          text,
        })
        .select()

      setNodes((nodes) =>
        nodes.map((node) => {
          console.log({ text })
          return node.data.id !== sheetId
            ? node
            : {
                ...node,
                data: {
                  ...node.data,
                  rowLabels: [
                    ...((node.data as UISheet).rowLabels || []),
                    {
                      id: data?.length ? data[0].id : "",
                      text,
                      rowIndex,
                    },
                  ],
                },
              }
        })
      )
    } else {
      await supabase
        .from("row_label")
        .update({
          text,
        })
        .eq("sheet_id", sheetId)
        .eq("row_index", rowIndex)

      setNodes((nodes) =>
        nodes.map((node) =>
          node.data.id !== sheetId
            ? node
            : {
                ...node,
                data: {
                  ...node.data,
                  rowLabels: [
                    ...((node.data as UISheet).rowLabels || []).map(
                      (rowLabel) =>
                        rowLabel.rowIndex === rowIndex
                          ? { ...rowLabel, text }
                          : rowLabel
                    ),
                  ],
                },
              }
        )
      )
    }

    setPendingNodesUpdate(false)
  }

  const handleSignOut = async () => {
    await supabase.auth.signOut()
  }

  const [isLoadingAIResponse, setIsLoadingAIResponse] = useState(false)
  const [matrixLabel, setMatrixLabel] = useState("")
  const [editedMatrixProps, setEditedMatrixProps] = useState<
    | {
        id: string
        labelType: string
      }
    | undefined
  >()

  const handleOpenMatrixLabelEditor = (
    matrixId: string,
    labelType: string,
    labelText?: string
  ) => {
    setEditedMatrixProps({ id: matrixId, labelType })
    setMatrixLabel(labelText || "")
    setMatrixLabelEditorOpen(true)
    setTimeout(() => {
      matrixLabelInputRef?.current?.focus()
    }, 200)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && isMatrixLabelEditorOpen) {
      handleConfirmMatrixLabel()
    }
  }

  useEffect(() => {
    document.addEventListener("keyup", handleKeyDown)

    return () => {
      document.removeEventListener("keyup", handleKeyDown)
    }
  }, [isMatrixLabelEditorOpen, editedMatrixProps, matrixLabel])

  const handleConfirmMatrixLabel = () => {
    if (!editedMatrixProps) {
      return
    }

    const editedLabelPropName = editedMatrixProps.labelType

    setNodes((nodes) =>
      nodes.map((node) =>
        node.data.id !== editedMatrixProps.id
          ? node
          : {
              ...node,
              data: { ...node.data, [editedLabelPropName]: matrixLabel },
            }
      )
    )

    setMatrixLabelEditorOpen(false)
    setMatrixLabel("")

    supabaseConnector.updateMatrixLabel(
      editedLabelPropName,
      matrixLabel,
      editedMatrixProps.id
    )
  }

  const handleAutogenerateCanvas = async (
    autogeneratePrompt: string,
    filesToUpload: File[]
  ) => {
    try {
      setIsLoadingAIResponse(true)

      let imageBase64: string | undefined
      const file = filesToUpload?.length ? filesToUpload[0] : undefined

      if (file) {
        if (file.type === "application/pdf") {
          const pdfBuffer = await file.arrayBuffer()
          const images = await PdfToImg(pdfBuffer)
          imageBase64 = images ? images[0] : undefined
        } else {
          imageBase64 = (await fileToBase64(file)) as string
        }
      }

      const importedApiJSON = await supabaseConnector.autogenerateCanvas(
        autogeneratePrompt,
        imageBase64
      )

      if (importedApiJSON.matrices) {
        const matrices = await parseAutogenMatrices(
          importedApiJSON,
          canvas?.id!,
          reactFlowRef.current!
        )
        setNodes((nodes) => [...nodes, ...matrices])
      } else {
        const {
          importedSheets,
          importedLogics,
          importedEdges,
          sheetsData,
          cellsData,
          edgesData,
          logicsData,
          columnLabelsData,
        } = await parseAutogenSheets(
          importedApiJSON,
          canvas?.id!,
          reactFlowRef.current!
        )

        setNodes((nodes) => [...nodes, ...importedSheets, ...importedLogics])
        setEdges((edges) => [...edges, ...importedEdges])

        await supabaseConnector.importCanvas(
          sheetsData,
          cellsData,
          edgesData,
          logicsData,
          columnLabelsData
        )

        setAutogenerateResult({
          sheetsData,
          cellsData,
          edgesData,
          logicsData,
          columnLabelsData,
        })
      }

      setAutogenerateResultsDialogOpen(true)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingAIResponse(false)
      setAutogenerateDialogOpen(false)
    }
  }

  const handleViewportChange = debounde(async (viewport: Viewport) => {
    const { data: hasUserSettings } = await supabase
      .from("user_settings")
      .select()
      .eq("canvas_id", canvas!.id)

    if (!hasUserSettings?.length) {
      await supabase.from("user_settings").insert({
        canvas_x: viewport.x,
        canvas_y: viewport.y,
        canvas_zoom: viewport.zoom,
        canvas_id: canvas!.id,
      })
    } else {
      await supabase
        .from("user_settings")
        .update({
          canvas_x: viewport.x,
          canvas_y: viewport.y,
          canvas_zoom: viewport.zoom,
        })
        .eq("canvas_id", canvas!.id)
    }
  }, 500)

  const handleUpdateSheetName = async (sheet: UISheet, value: string) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.data.id === sheet.id
          ? { ...node, data: { ...node.data, name: value } }
          : node
      )
    )

    await supabaseConnector.updateSheetName(sheet)
  }

  const handleUpdateColumnLabelsLabel = async (
    sheet: UISheet,
    value: string
  ) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.data.id === sheet.id
          ? { ...node, data: { ...node.data, columnLabelsLabel: value } }
          : node
      )
    )

    await supabaseConnector.updateColumnLabelsLabel(sheet.id, value)
  }

  const handleUpdateRowLabelsLabel = async (sheet: UISheet, value: string) => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.data.id === sheet.id
          ? { ...node, data: { ...node.data, rowLabelsLabel: value } }
          : node
      )
    )

    await supabaseConnector.updateRowLabelsLabel(sheet.id, value)
  }

  const handleCanvasNameChange = async (value: string) => {
    await supabase.from("canvas").update({ name: value }).eq("id", canvas?.id)
  }

  const [selectedCell, setSelectedCell] = useState<UICell>()
  const [editedCell, setEditedCell] = useState<UICell>()
  const [isAutogenerateDialogOpen, setAutogenerateDialogOpen] = useState(false)
  const [isAutogenerateResultsDialogOpen, setAutogenerateResultsDialogOpen] =
    useState(false)
  const [autogenerateResult, setAutogenerateResult] = useState<any>(undefined)

  const handleCellClick = (cell: UICell) => {
    if (selectedCell?.id === cell.id) {
      setEditedCell(cell)
    } else {
      setSelectedCell(cell)
    }
  }

  const listener = (event: any) => {
    if (!selectedCell || selectedCell.id === editedCell?.id) {
      return
    }

    const pastedText = event.clipboardData?.getData("text")
    // Remove \r to handle cases \r\n and \n in the same way.
    const rows = pastedText?.replaceAll("\r", "").split("\n")
    const pastedCells: Record<string, string> = {}
    rows.forEach((row: string, rowIndex: number) => {
      row.split("\t").forEach((text: string, columnIndex: number) => {
        pastedCells[
          `${selectedCell.rowIndex + rowIndex}-${
            selectedCell.columnIndex + columnIndex
          }`
        ] = text
      })
    })

    handlePaste(selectedCell.sheetId, pastedCells)
  }

  useEffect(() => {
    window.addEventListener("paste", listener)

    return () => window.removeEventListener("paste", listener)
  }, [selectedCell, editedCell])

  const handleEdgeClick = (event: MouseEvent, edge: Edge) => {
    setSelectedEdges([edge.id])
  }

  const handlePaste = (
    sheetId: string,
    pastedCells: Record<string, string>
  ) => {
    const cellsToUpdate: UICell[] = []

    setNodes((nodes) =>
      nodes.map((node) =>
        node.data.id !== sheetId
          ? node
          : {
              ...node,
              data: {
                ...node.data,
                cells: (node.data as UISheet).cells.map((cell) => {
                  const pastedCell =
                    pastedCells[`${cell.rowIndex}-${cell.columnIndex}`]

                  if (pastedCell) {
                    const updatedCell = {
                      ...cell,
                      name: pastedCell,
                    }
                    cellsToUpdate.push(updatedCell)

                    return updatedCell
                  }

                  return cell
                }),
              },
            }
      )
    )

    if (cellsToUpdate.length) {
      supabaseConnector.updateCells(cellsToUpdate)
    }
  }

  const appContainerRef = useRef<HTMLDivElement>(null)

  const [cellWithContextMenu, setCellWithContextMenu] = useState<
    CellWithContextMenuInfoType | undefined
  >(undefined)

  const [showMinimap, setShowMinimap] = useState(false)
  const navigate = useNavigate()

  if (session === null) {
    window.location.assign(getFullURL("login"))
  }

  if (session === undefined || !isInitialized) {
    return (
      <div className="loading-indicator">
        <span className="loading-indicator-span-1"></span>
        <span className="loading-indicator-span-2"></span>
      </div>
    )
  }

  return (
    <>
      <div className="tools-container">
        {canvasPermissions[CanvasPermission.Dev] && (
          <button
            className="tool-button add-sheet-button"
            onClick={() => handleAddSheet()}
          >
            <AddSheetIcon />
            Sheet
          </button>
        )}

        <div className="tool-separator"></div>

        {canvasPermissions[CanvasPermission.Dev] && (
          <button
            className="tool-button add-sheet-button"
            onClick={() => handleAddSheet(true)}
          >
            <AddSheet2Icon />
            Matrix
          </button>
        )}

        <div className="tool-separator"></div>

        <button
          className="tool-button add-sheet-button"
          onClick={() => {
            setAutogenerateResult(undefined)
            setAutogenerateDialogOpen(true)
          }}
        >
          <AutogenerateCanvasIcon />
          Autogenerate
        </button>
        {/* <button
          className="tool-button configure-sheets-button"
          onClick={() => {
            setSheetSettingsOpen(true)
          }}
        >
          <ConfigureSheetsIcon className="configure-sheets-icon" />
          Configure Variables
        </button>
        <div className="tool-separator"></div> */}
        {canvasPermissions[CanvasPermission.PMBiz] ||
          (canvasPermissions[CanvasPermission.Dev] && (
            <div className="tool-button">
              {canvasPermissions[CanvasPermission.PMBiz] && (
                <div>
                  <input
                    type="radio"
                    id="userRolePMBiz"
                    name="drone"
                    value="pmBiz"
                    checked={userRole === "pmBiz"}
                    onChange={({ target }) => setUserRole(target.value)}
                  />
                  <label style={{ cursor: "pointer" }} htmlFor="userRolePMBiz">
                    PMBiz
                  </label>
                </div>
              )}
              {canvasPermissions[CanvasPermission.Dev] && (
                <div>
                  <input
                    type="radio"
                    id="userRoleDev"
                    name="userRole"
                    value="dev"
                    onChange={({ target }) => setUserRole(target.value)}
                    checked={userRole === "dev"}
                  />
                  <label style={{ cursor: "pointer" }} htmlFor="userRoleDev">
                    Dev
                  </label>
                </div>
              )}
              {canvasPermissions[CanvasPermission.PMBiz] &&
                canvasPermissions[CanvasPermission.Dev] && (
                  <div>
                    <input
                      type="radio"
                      id="userRoleSuperuser"
                      name="userRole"
                      value="superuser"
                      onChange={({ target }) => setUserRole(target.value)}
                      checked={userRole === "superuser"}
                    />
                    <label
                      style={{ cursor: "pointer" }}
                      htmlFor="userRoleSuperuser"
                    >
                      Superuser
                    </label>
                  </div>
                )}
              <div className="tool-separator"></div>
            </div>
          ))}

        {/* <div className="tool-button">
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              onChange={({ target }) => setIsSheetsUIVisible(target.checked)}
            ></input>
            Sheets UI
          </label>
        </div> */}
      </div>
      <AppContext.Provider
        value={{
          selectedNodes,
          selectedEdges,
          setSelectedNodes,
          removeCells: handleRemoveCells,
          removeEdge: handleRemoveEdge,
          sheetFields,
          updateLogic: handleUpdateLogic,
          updateCell: handleUpdateCell,
          updateSheet: handleUpdateSheet,
          connectionStart,
          onConnect: handleConnect,
          onAddRow: handleAddRow,
          onAddColumn: handleAddColumn,
          onAddCells: handleAddCells,
          onShrinkSheet: handleShrinkSheet,
          onRemoveRow: handleRemoveRow,
          onRemoveColumn: handleRemoveColumn,
          onUpdateColumnLabel: handleUpdateColumnLabel,
          onUpdateRowLabel: handleUpdateRowLabel,
          setIsEditingLabel,
          isPendingApiResponse,
          isSheetsUIVisible,
          pendingSheetSelectionId,
          setPendingSheetSelectionId,
          setHighlightedPathDestCellId: handleHighlightedPathDestCellIdChange,
          highlightedPathCellIds,
          highlightedPathLogicIds,
          onUpdateSheetName: handleUpdateSheetName,
          onUpdateColumnLabelsLabel: handleUpdateColumnLabelsLabel,
          onUpdateRowLabelsLabel: handleUpdateRowLabelsLabel,
          sheetValidationErrors,
          onPaste: handlePaste,
          onCellClick: handleCellClick,
          selectedCell: selectedCell,
          editedCell: editedCell,
          setEditedCell: setEditedCell,
          onOpenMatrixLabelEditor: handleOpenMatrixLabelEditor,
          cellWithContextMenu,
          setCellWithContextMenu,
        }}
      >
        <ReactFlow
          ref={appContainerRef}
          onMove={(_, viewport: Viewport) => {
            handleViewportChange(viewport)
          }}
          defaultViewport={defaultViewport}
          // Canvas boudaries
          // translateExtent={[
          //   // left, top
          //   [-1500, -1000],
          //   // right, bottom
          //   [3000, 2000],
          // ]}
          // // Node boudaries
          // // Coordinates that set the area for adding / moving sheets
          // nodeExtent={[
          //   [-1250, -750],
          //   [2750, 1750],
          // ]}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeClick={handleEdgeClick}
          onConnect={handleConnect}
          onInit={handleInit}
          proOptions={{ hideAttribution: true }}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          minZoom={0.1}
          deleteKeyCode={null}
          zoomOnDoubleClick={false}
          // maxZoom={1}
          // nodesFocusable
          panOnDrag={!(editedCell || isEditingLabel)}
          nodesDraggable={
            !(editedCell || isEditingLabel) &&
            (userRole === "pmBiz" || userRole === "superuser")
          }
          elementsSelectable={userRole === "pmBiz" || userRole === "superuser"}
          // onSelectionChange={handleSelectionChange}
          onNodeDrag={handleNodeDrag}
          onNodeDragStop={handleNodeDragStop}
          connectionLineComponent={EdgePreview}
          onConnectStart={(_, { nodeId, handleId }) =>
            setConnectionStart({ source: nodeId, sourceHandle: handleId })
          }
          onConnectEnd={() => setConnectionStart(undefined)}
          onPaneClick={() => {
            if (selectedNodes.length) {
              setSelectedNodes([])
              setSelectedCell(undefined)
              setEditedCell(undefined)
              setCellWithContextMenu(undefined)
            }

            if (selectedEdges.length) {
              setSelectedEdges([])
            }
          }}
          onNodeClick={(_, node) => setSelectedNodes([node.id])}
        >
          <Controls showInteractive={false}>
            <div className="map-controls-button">
              <MinimapIcon
                style={{ width: 19, height: 19 }}
                onClick={() => setShowMinimap(!showMinimap)}
              />
            </div>
          </Controls>
          <Background color="#aaa" gap={BACKGROUND_DOTS_GAP} />
          {showMinimap && <MiniMap />}
        </ReactFlow>
      </AppContext.Provider>

      {customerSettings?.subscription_active ? (
        <AutogenModal
          appElement={appContainerRef.current || undefined}
          isOpen={isAutogenerateDialogOpen}
          onRequestClose={() => setAutogenerateDialogOpen(false)}
          isLoadingAIResponse={isLoadingAIResponse}
          onConfirm={handleAutogenerateCanvas}
        />
      ) : (
        <UpgradeToProModal
          isOpen={isAutogenerateDialogOpen}
          onRequestClose={() => setAutogenerateDialogOpen(false)}
          customerTeam={session?.user?.app_metadata?.customer_team}
        />
      )}

      <ReactModal
        appElement={appContainerRef.current || undefined}
        isOpen={isAutogenerateResultsDialogOpen && !!autogenerateResult}
        onRequestClose={() => setAutogenerateResultsDialogOpen(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            padding: 10,
            zIndex: 10,
            borderRadius: 5,
            bottom: "auto",
          },
          overlay: {
            zIndex: 10,
            background: "rgba(0,0,0,0.1)",
          },
        }}
      >
        <div>
          <div style={{ marginBottom: 20 }}>Generated:</div>
          {autogenerateResult?.sheetsData?.length ? (
            <div style={{ marginBottom: 10 }}>
              {autogenerateResult?.sheetsData?.length}{" "}
              {autogenerateResult?.sheetsData?.length === 1
                ? "Sheet"
                : "Sheets"}
            </div>
          ) : null}
          {autogenerateResult?.cellsData?.length ? (
            <div style={{ marginBottom: 10 }}>
              {autogenerateResult?.cellsData?.length}{" "}
              {autogenerateResult?.cellsData?.length === 1 ? "Cell" : "Cells"}
            </div>
          ) : null}
          {autogenerateResult?.logicsData?.length ? (
            <div style={{ marginBottom: 10 }}>
              {autogenerateResult?.logicsData?.length}{" "}
              {autogenerateResult?.logicsData?.length === 1
                ? "Logic"
                : "Logics"}
            </div>
          ) : null}
          {autogenerateResult?.edgesData?.length ? (
            <div style={{ marginBottom: 20 }}>
              {autogenerateResult?.edgesData?.length}{" "}
              {autogenerateResult?.edgesData?.length === 1
                ? "Connection"
                : "Connections"}
            </div>
          ) : null}
        </div>
        <button
          className="api-button"
          onClick={() => setAutogenerateResultsDialogOpen(false)}
          style={{ marginTop: 5 }}
        >
          Close
        </button>
      </ReactModal>

      {customerSettings?.subscription_active ? (
        <PublishApiModal
          open={!!(isApiPreviewVisible && apiPreview)}
          onClose={() => setIsApiPreviewVisible(false)}
          onPublishToFirebase={handlePublishToFirebase}
          onPublish={handlePublish}
          userRole={userRole}
          canvasPermissions={canvasPermissions}
          apiPreview={apiPreview}
          canvasId={canvas?.id!}
        />
      ) : (
        <UpgradeToProModal
          isOpen={isApiPreviewVisible}
          onRequestClose={() => setIsApiPreviewVisible(false)}
          customerTeam={session?.user?.app_metadata?.customer_team}
        />
      )}

      <div className="api-controls">
        {!isApiPreviewVisible && (
          <button
            onClick={handleViewAndDeployAPI}
            className="view-api-button api-button"
            disabled={isLoadingAPI}
          >
            View and Publish API
          </button>
        )}
      </div>

      <div className="user-menu">
        <span
          className="user-menu-item"
          onClick={() =>
            setUserModalOpen((isUserModalOpen) => !isUserModalOpen)
          }
        >
          {session?.user?.email}
        </span>
        <span> / </span>
        <input
          value={canvasName}
          onChange={({ target }) => setCanvasName(target.value)}
          onBlur={({ target }) => {
            handleCanvasNameChange(target.value)
          }}
          className="canvas-name-input"
        />
      </div>

      <ReactModal
        appElement={appContainerRef.current || undefined}
        isOpen={isUserModalOpen}
        onRequestClose={() => setUserModalOpen(false)}
        style={{
          content: {
            top: 45,
            left: 20,
            width: 200,
            height: 100,
            padding: 10,
            zIndex: 10,
            border: "1px solid #dfdfdf",
          },
          overlay: {
            zIndex: 10,
            background: "rgba(0,0,0,0.1)",
          },
        }}
      >
        <button onClick={handleSignOut} className="sign-out-button">
          Sign Out
        </button>
      </ReactModal>

      <ReactModal
        appElement={appContainerRef.current || undefined}
        isOpen={isMatrixLabelEditorOpen}
        onRequestClose={() => setMatrixLabelEditorOpen(false)}
        style={{
          content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: 250,
            height: 80,
            padding: 10,
            zIndex: 10,
            border: "1px solid #dfdfdf",
          },
          overlay: {
            zIndex: 10,
            background: "rgba(0,0,0,0.1)",
          },
        }}
      >
        <div>Label</div>
        <input
          ref={matrixLabelInputRef}
          style={{ border: "1px solid #484848" }}
          value={matrixLabel}
          onChange={({ target }) => {
            setMatrixLabel(target.value)
          }}
        />
        <button
          onClick={handleConfirmMatrixLabel}
          className="api-button"
          style={{ marginLeft: 10 }}
        >
          Confirm
        </button>
      </ReactModal>
    </>
  )
}

export default App
